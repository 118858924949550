@import url('https://fonts.googleapis.com/css2?family=Rajdhani:wght@300;400;500;600;700&display=swap');

/* Form container */
.form {
  max-width: 600px; /* Increased max-width for better layout */
  margin: 0 auto;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: flex-end;
  padding: 20px; /* Added padding for better spacing */
  background-color: #f0eeee; /* Light background color for contrast */
  border-radius: 10px; /* Rounded corners for better aesthetics */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  border: 1px solid black;
  font-family: 'Rajdhani', sans-serif; /* Added font-family */
}

/* Form field */
.field {
  width: 100%;
  margin-bottom: 20px;
}

/* Half-width field */
.field.half {
  width: calc(50% - 10px); /* Adjusted for better spacing */
}

/* Last field alignment */
.field.last {
  margin-left: auto;
}

/* Input fields */
.text-input,
.textarea,
select {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 8px; /* Increased border radius for smoother edges */
  margin-top: 5px;
  font-family: 'Rajdhani', sans-serif; /* Added font-family */
}

/* Textarea */
.textarea {
  resize: vertical;
  height: 120px; /* Increased height for better usability */
  padding: 10px;
  font-family: 'Rajdhani', sans-serif; /* Added font-family */
}

/* Select dropdown */
select {
  appearance: none;
  background-color: #fff;
  background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5'%3E%3Cpath fill='%23333' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 10px center;
  background-size: 10px;
  padding-right: 30px; /* Added padding to accommodate dropdown arrow */
  font-family: 'Rajdhani', sans-serif; /* Added font-family */
}

/* Label styling */
.label {
  font-weight: bold;
  color: #333; /* Darker color for better readability */
  padding-top: 0;
  padding-left: 0;
  letter-spacing: .025em;
  font-size: 1.125em;
  line-height: 1.25;
  position: relative;
  display: block;
  margin-bottom: 5px;
  font-family: 'Rajdhani', sans-serif; /* Added font-family */
}

/* Required label indicator */
.label.required:after {
  content: '*';
  color: red; /* Red color for required indicator */
  font-weight: normal;
  font-size: .75em;
  vertical-align: top;
}

/* Button styles */
.button-group {
  display: flex;
}

.button {
  font: inherit;
  line-height: normal;
  cursor: pointer;
  background: #5adaff; /* Vibrant button color */
  color: #fff;
  font-weight: bold;
  padding: 10px 20px;
  border: none; /* Removed border for cleaner look */
  border-radius: 5px;
  margin-right: 10px; /* Adjusted spacing between buttons */
  transition: background-color 0.3s ease, padding 0.3s ease; /* Added transition */
  font-family: 'Rajdhani', sans-serif; /* Added font-family */
}

/* Button hover, focus, and active styles */
.button:hover,
.button:focus,
.button:active {
  background-color: #5468ff; /* Darker color on hover */
  padding: 12px 22px; /* Adjusted padding on hover */
}

.button:active {
  position: relative;
  top: 1px;
}

/* Button-post specific styles */
.button-post {
  background-color: #5adaff;
  border: 2px solid black;
  border-radius: 4px;
  color: #fff;
  cursor: pointer;
  font-size: 16px;
  padding: 10px 20px;
  transition: background-color 0.3s ease;
  width: 120px;
  height: 44px;
  font-family: 'Rajdhani', sans-serif; /* Added font-family */
}

.button-post:hover {
  background-color: #5adaff;
  margin: 2px;
}

/* Success message */
.success-message {
  color: green;
  background-color: #d4edda;
  border: 1px solid #c3e6cb;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 15px;
  font-weight: bold;
  font-family: 'Rajdhani', sans-serif; /* Added font-family */
}

/* Custom checkbox styling */
.checkbox-label {
  display: flex;
  align-items: center;
  font-family: 'Rajdhani', sans-serif; /* Added font-family */
}

.checkbox-label input[type="checkbox"] {
  appearance: none;
  width: 18px;
  height: 18px;
  border: 2px solid black;
  border-radius: 3px;
  outline: none;
  cursor: pointer;
  margin-right: 8px;
}

.checkbox-label input[type="checkbox"]:checked {
  background-color: black;
}

.checkbox-label {
  color: black;
}
